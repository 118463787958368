









































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import PersonService from "@/services/PersonService";
import {namespace} from "vuex-class";
import PersonDTO from "@/dto/person/PersonDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromPerson} from "@/dto/auth/Workspace";
import CustomerAccounts from "@/dto/CustomerAccounts";
import MergeAccountModal from "@/components/profile/admin/MergeAccountModal.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {PhoneInput}
})
export default class MergePersonSsnModal extends Vue{
    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private message = "";

    private successful = false;

    @Prop()
    private onSuccess!: () => void

    @Prop()
    private request!: CreatePersonRequestDTO;

    @Prop()
    private existPerson!: PersonDTO;

    public maskHandle() {
        let mask = "___-__-____";
        let oldVal = this.request.ssn!.replace(/\D/g, "");

        if (mask.length >= this.request.ssn!.length) {
            this.request.ssn = this.buildPhoneWithMask(mask, oldVal);
        } else if (mask.length < this.request.ssn!.length){
            this.request.ssn = this.request.ssn!.substr(0, mask.length)
        }
    }

    private buildPhoneWithMask(mask: string, oldVal: string) {
        let i = 0;

        let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a:string) {
            return i < oldVal.length ? oldVal.charAt(i++) : a
        }));

        if (newValue.length <= mask.length) {
            oldVal = newValue;
        }

        if (this.request.ssn!.substr(oldVal.length -1) == "-") {
            newValue = oldVal.substr(0, oldVal.length);
        }

        return newValue;
    }

    get isPhoneFilled(){
        return (this.request.phone != undefined || this.request.phone != null) && this.request.phone != "" && this.request.phone.length >= 7;
    }

    private deleteSuffixIfNeed(newValue: string) {
        if  (newValue.indexOf("_") != -1) {
            newValue = newValue.substr(0, newValue.indexOf("_"))
        }

        if (newValue.substr(newValue.length - 1) == "-") {
            newValue = newValue.substr(0, newValue.length - 1);
        }

        let suffix = newValue.substr(newValue.length - 2);

        if (suffix == " (" || suffix == ") ") {
            newValue = newValue.substr(0, newValue.length - 2)
        }

        return newValue;
    }

    closeModal(){
        this.$modal.hideAll();
    }

    errorHandling(err: any) {
        this.successful = false;
        let systemCode = err?.response?.data.systemCode;
        let param = err?.response?.data.param;
        let person: PersonDTO;
        let customerAccounts: CustomerAccounts;
        this.$modal.hide("hide")
        switch (systemCode) {
            case 40023:
			case 40033:
                person = param;
                if (!(person.email == null && this.request.email != null)) {
                    this.openLinksModal(person);
                }
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
                    {ssn: person.ssn, lastName: person.lastName, firstName: person.firstName, tin: person.nationalTin}) as string;
                break;
            case 40025:
                customerAccounts = param;
                this.openMergeAccountModal(customerAccounts)
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
                    {
                        lastName: customerAccounts.person?.lastName,
                        firstName: customerAccounts.person?.firstName,
                        listCompanyNames: this.handleCompaniesName(customerAccounts.companies)
                    }) as string;
                break
            default:
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
        }
        this.stopLoading();
    }

    handleCompaniesName(companies: Array<CompanyDTO>): string {
        let res = "";
        for (let i = 0; i < companies.length; i++) {
            res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
        }
        return res;
    }

    openMergeAccountModal(customerAccounts: CustomerAccounts) {
        this.$modal.show(
            MergeAccountModal,
            {
                customerAccounts: customerAccounts,
                personReq: this.request,
                onSuccess: () => this.handleSuccess()
            }
        );
    }

    openLinksModal(person: PersonDTO) {
        this.$modal.show(
            AdminLinksModal,
            {
                workspace: fromPerson(person),
                name: person.lastName?.concat(" ").concat(person.firstName as string)
            }
        );
    }

    handleSuccess() {
        this.onSuccess();
        this.stopLoading();
        this.closeModal();
    }

    save() {
        this.$validator.validate().then(
            isValid => {
                if (isValid) {
                    this.startLoading()
                    if (this.request.phone != null) {
                        if (7  > this.request.phone.length) {
                            this.request.phone = null;
                        }
                    }
                    this.message = ""
                    this.request.isMerge = true;
                    this.request.email = this.request.email?.trim() == '' ? null : this.request.email;
                    PersonService.registerCustomer(this.request).then(
                        () => this.handleSuccess(),
                        err => this.errorHandling(err)
                    )
                }
            }
        )
    }
}
